<template>
  <div>
    <Suspense>
      <template v-if="isLoading">
        <CardLoadingDefault v-if="cardToRender === 'default'" />
        <CardLoadingBlockHorizontal v-else-if="cardToRender === 'block-new'" />
        <CardLoadingBlock v-else />
      </template>
      <template v-else>
        <RestaurantCardDefault
          v-if="cardToRender === 'default'"
          v-bind="props"
          @on-click="onCardClicked"
        />
        <RestaurantCardBlockHorizontal
          v-else-if="cardToRender === 'block-new'"
          v-bind="props"
          @on-click="onCardClicked"
        />
        <RestaurantCardBlock v-else v-bind="props" @on-click="onCardClicked" />
      </template>
      <template #fallback>
        <CardLoadingDefault v-if="cardToRender === 'default'" />
        <CardLoadingBlockHorizontal v-else-if="cardToRender === 'block-new'" />
        <CardLoadingBlock v-else />
      </template>
    </Suspense>
  </div>
</template>

<script lang="ts" setup>
import { toRefs, defineAsyncComponent, shallowRef } from "vue";
import type { RestaurantCardProps } from "~/types/Restaurant";

const props = withDefaults(defineProps<RestaurantCardProps>(), {
  cardToRender: "default",
});
const emit = defineEmits(["on-click"]);
const CardLoadingBlock = defineAsyncComponent(
  () => import("./Loading/CardLoadingBlock.vue")
);
const CardLoadingDefault = defineAsyncComponent(
  () => import("./Loading/CardLoadingDefault.vue")
);
const CardLoadingBlockHorizontal = defineAsyncComponent(
  () => import("./Loading/CardLoadingBlockHorizontal.vue")
);
const RestaurantCardDefault = defineAsyncComponent(
  () => import("./Type/CardDefault.vue")
);
const RestaurantCardBlock = defineAsyncComponent(
  () => import("./Type/CardBlock.vue")
);
const { isLoading, cardToRender } = toRefs(props);
const RestaurantCardBlockHorizontal = defineAsyncComponent(
  () => import("./Type/CardBlockHorizontal.vue")
);
// --- card to render
const componentCardToRender = shallowRef();
const selectComponentToRender = () => {
  if (cardToRender.value === "block-new") {
    componentCardToRender.value = RestaurantCardBlockHorizontal;
    return;
  }

  componentCardToRender.value =
    cardToRender.value === "default"
      ? RestaurantCardDefault
      : RestaurantCardBlock;
};
selectComponentToRender();
// --- component loader to render
const componentLoaderToRender = shallowRef();
const selectLoaderComponentToRender = () => {
  if (cardToRender.value === "block-new") {
    componentLoaderToRender.value = CardLoadingBlockHorizontal;
    return;
  }
  componentLoaderToRender.value =
    cardToRender.value === "default" ? CardLoadingDefault : CardLoadingBlock;
};

selectLoaderComponentToRender();
function onCardClicked(cardObj: RestaurantCardProps) {
  emit("on-click", cardObj);
}
</script>
